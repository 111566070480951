<template>
  <div>
  
    <div contenteditable v-model="testEditable" @input="userInput" ref="editable-element">
text text text<br>text text text<br>text text text<br>
    </div>
    {{testEditable
    }}
  </div>
</template>

<script>

import { 
  BCol,
  BRow,
  BIcon,
  BSkeleton,
  BCollapse,
  BTabs,
  BTab,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
 } from 'bootstrap-vue';
 
export default {
  components: {
    BCol,
    BRow,
    BIcon,
    BSkeleton,
    BCollapse,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
  },
  data() {
    return {
      testEditable: "gamer"
    }
  },
  mounted () { 
  },
  methods: {
    async userInput(){
      await this.$nextTick()

      const el = this.$refs['editable-element']
      const content = el.textContent

      // this.$refs['editable-element'].innerHTML = content
      // this.$refs['editable-element'].focus()
      // await this.$nextTick()
      // console.log(el)
      // var end = this.$refs['editable-element'].selectionEnd;
      // this.$refs['editable-element'].selectionEnd= end + 7;
      var range = document.createRange()

      var sel = window.getSelection()
      
      range.setStart(el.childNodes[2], 5)
      range.collapse(false)
      
      sel.removeAllRanges()
      sel.addRange(range)
      // console.log(el.selec)
      // this.$refs['editable-element'].selectionStart = 3
      // this.$refs['editable-element'].setSelectionRange(1,5)
    }
  },
};  
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

</style>